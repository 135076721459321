import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Link } from "gatsby";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Re-Render When Data Changes`}</h1>
    <p>{`Do you have properties that are separate but related? A good example
would be tracking fetch status, result, and error.`}</p>
    <ul>
      <li parentName="ul">
        <Link to="hooks/useReducer" mdxType="Link">
  Yes, there are separate but related properties I need to change at the same
  time.
        </Link>
        <p parentName="li">{`  For example, upon successful fetch, I want to change the `}<inlineCode parentName="p">{`status`}</inlineCode>{` to `}<inlineCode parentName="p">{`done`}</inlineCode>{`
and `}<inlineCode parentName="p">{`result`}</inlineCode>{` to the response object.`}</p>
      </li>
      <li parentName="ul">
        <Link to="hooks/useState" mdxType="Link">
  No, I'm just tracking a simple, single, isolated value.
        </Link>
      </li>
    </ul>

    </MDXLayout>;
}
MDXContent.isMDXComponent = true;
      